@import url('https://fonts.googleapis.com/css?family=Quicksand');

#root{
    margin-top: 3%;
}

.body{
    height: 100%;
    margin: 0px;
    padding: 0px;
    
}

.ui.segment.inner{
    background: #00e1ff27;
    font-family: 'Quicksand', sans-serif;
}

.ui.teal.inverted.menu{
    font-family: 'Quicksand', sans-serif;
}

.ui.button{
    font-family: 'Quicksand', sans-serif;
}

.ui.bottom.right.popup.transition.visible{
    font-family: 'Quicksand', sans-serif;
}

.ui.teal.segment.active.tab.dark{
    background: #2e2e2e;
}

.ui.teal.segment.active.tab.light{
    background: #d4d4d4;
}

.ui.teal.segment:not(.inverted){
    border-top: 5px solid #00b5ad !important ;
    border-bottom: 5px solid #00b5ad !important;
    border-left: 5px solid #00b5ad !important;
    border-right: 5px solid #00b5ad !important;
}

@media all and (max-width: 1000px) { /* i5 / se*/
    .ui.button.butontry{
        position: absolute;
        right: 0px;
        background: #00b5ad;
        color: #fff 
    }
    
    .ui.button{
        background: #00b5ad;
        color: #fff 
    }

    body > div.ui.bottom.right.popup.transition.visible{ /* poup color for coop*/
        background-color: #00b5ad;
        color: white;
    }

    .ui.bottom.center.popup.transition.visible.popupTheme{
        background-color: #00b5ad;
        color: white;
    }
    /*////////////////////////////////////////////////////*/
    .radioButtons{
        -webkit-overflow-scrolling: touch;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
        text-align: center;
        margin-top: -30px;
        margin-left: -25px;
    }

    div.radioButtons button.finishButton{
        margin-left: 3%;
    }
       
    input[type='radio'] {
        margin-left: 25px;
        margin-top: 25px;
        transform: scale(2); 
    }
    
    input[type="radio"] {
        background-color: transparent;
        border: 0.04em solid #2e2e2e;
        border-radius: 50%;
        box-shadow: inset 0 0 0 0 white;
        cursor: pointer;
        font: inherit;
        height: 1em;
        outline: none;
        width: 1em;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    
    input[type="radio"]:checked {
          background-color: #d4d4d4;
          box-shadow: inset 0 0 0 .1875em #00b5ad;
          -webkit-transition: background .15s, box-shadow .1s; 
          transition: background .15s, box-shadow .1s; 
    }

    input[type="radio"]:disabled {
        border: 0.150em solid #f79361;
        background-color: #f79361;
        color: #666666;
    }
}

@media all and (min-width: 1001px){ /* tablet and beyond */
    .ui.button.butontry{
        position: absolute;
        right: 18px;
    }
    
    .ui.button{
        background: #00b5ad;
        color: #fff /* fix this*/
    }

    .ui.bottom.center.popup.transition.visible.popupTheme{
        background-color: #00b5ad;
        color: white;
    }
    .radioButtons{
        text-align: center;
        margin-top: -30px;
        margin-left: -60px;
    }

    div.radioButtons button.finishButton{
        margin-left: 4%
    }
       
    input[type='radio'] {
        margin-left: 40px;
        margin-top: 40px;
        transform: scale(3);
    }
    
    input[type="radio"] {
        background-color: transparent;
        border: .1em solid #2e2e2e;
        border-radius: 50%;
        box-shadow: inset 0 0 0 0 white;
        cursor: pointer;
        font: inherit;
        height: 1em;
        outline: none;
        width: 1em;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    
    input[type="radio"]:checked {
          background-color: #d4d4d4;
          box-shadow: inset 0 0 0 .1875em #00b5ad;
          -webkit-transition: background .15s, box-shadow .1s; 
          transition: background .15s, box-shadow .1s; 
    }
    
    input[type="radio"]:hover {
        border: 0.150em solid #f79361;
        background-color: #f79361;
    }
    
    .try{
        background-color: #5650d3;
    }
    
    input[type="radio"]:disabled {
        border: 0.150em solid #f79361;
        background-color: #f79361;
        color: #666666;
    }
}

#root > div > div > div > div:nth-child(2){
    padding-top: 0%;
}

div.ui.segment.credits{
    background-color: #00b5ad;
    color: #e6f8f7;
    font: 14px 'Lato';
    text-align: end;
    padding-top: 1%;
    padding-bottom: 1%;
    font-family: 'Quicksand', sans-serif;
}

div.ui.segment.fade.notification{
    display: block;
    position: fixed;
    left: 50%;
    bottom: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0 auto;
    background-color: #f79361;
    color: #292424;
}

/* CO-OP BUTTON DESKTOP */

div div button.ui.button.coop-button{
    background-color: #f79361;
}

div.ui.page.modals.dimmer.transition.visible.active  div.actions button.ui.icon.positive.right.labeled.button{
    background-color: #00b5ad
}

div.ui.page.modals.dimmer.transition.visible.active  div.actions button.ui.icon.positive.right.labeled.button:hover{
    background-color: #01726c
}

div.ui.mini.modal.transition.visible.active div.content{
    background-color: #00b5ad
}

/* NOTIFICATION */
.scoreLink{
    color: #00b5ad;
}

.scoreLink:hover{
    cursor:pointer
}

.room-id{
    color:#00b5ad
}

/*div.ui.modal{/* Fix for the white lines
    background-color: #2e2e2e
}*/

div.ui.modal.transition.visible.active div.header{
    color: #d4d4d4;
    background-color: #2e2e2e;
}

div.ui.modal.transition.visible.active div.image.content{
    color: #d4d4d4;
    background-color: #2e2e2e;
}

div.description i.spinner.icon{
    margin-left: 0.5rem
}

div.description i.spinner.loading.icon{
    margin-left: 0.5rem
}

div.description i.checkmark.icon{
    color: green;
    margin-left: 0.5rem
}

div.ui.bottom.right.popup.transition.visible{
    background-color: #00b5ad;
}

button.ui.teal.icon.left.labeled.button{
    background-color: #009189
}

button.ui.teal.icon.left.labeled.button:hover{
    background-color: #006e69
}

div.ui.basic.center.aligned.segment i.close.icon.close{
    color: red;
    position: absolute;
    top: 0px;
    right: 0px;
}

div.ui.basic.center.aligned.segment i.close.icon.close:active{
    color: rgb(126, 0, 0);
}

div.ui.pointing.menu{
    background-color: #00b5ad;
}

div.menu.transition.visible{
    background-color: #00b5ad;
}

div.menu.transition.visible div span{
    color: #f5f5f5;
}

div.menu.transition.visible div.header{
    color: #f5f5f5;
}

h1.emptyscoretext{
    color: #d4d4d4;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 25px;
}

div button.ui.button.emptyscorebutton{
    text-align: center;
}

/* CO-OP POPUP*/

div.ui.basic.center.aligned.segment h4{
    color: #fff;
}

div.ui.basic.center.aligned.segment div.ui.horizontal.divider{
    color: #fff
}

.rid-text{
    color: rgb(51, 255, 0)
}

/* CHARTS */

div.ui.teal.segment.active.tab.dark .recharts-legend-item-text{
    color: #d4d4d4;
}

div.ui.teal.segment.active.tab.light .recharts-legend-item-text{
    color: #2e2e2e;
}

div.recharts-wrapper.dark .recharts-legend-item-text{
    color: #d4d4d4;
}

div.recharts-wrapper.light .recharts-legend-item-text{
    color: #2e2e2e;
}

.e-score-chart-light h1.emptyscoretext{ /* TEXT */
    color: #2e2e2e;
}

.e-score-chart-light{ /* CONTAINER */
    text-align: center;
}

.e-score-chart-dark{
    text-align: center
}

div.recharts-tooltip-wrapper div.recharts-default-tooltip{
    /* background-color: #00b5ad !important */
}

/*DARK AND LIGHT TABLE DESKTOP*/

div.ui.teal.segment.active.tab.dark tr th.center.aligned{
    color: #d4d4d4;
    background-color: #00b5ad;
}

div.ui.teal.segment.active.tab.dark tr td.center.aligned{
    color: #6cbfcc;
    background-color: #38666d;
}

div.ui.teal.segment.active.tab.light tr th.center.aligned{
    color: #d4d4d4;
    background-color: #00b5ad;
}


div.ui.teal.segment.active.tab.light tr td.center.aligned{
    color: #566899;
    background-color: #d7faff;
}

/*DARK AND LIGHT TABLE MOBILE*/

table.ui.celled.definition.table.dark {
    background-color: #00b5ad;
}

table.ui.celled.definition.table.dark tr td{
    background-color: #38666d;
    color: #6cbfcc;
}

table.ui.celled.definition.table.light {
    background-color: #00b5ad;
}

table.ui.celled.definition.table.light tr td{
    color: #566899;
    background-color: #d7faff;
}

/* HOW TO*/

section.dark{
    color: #d4d4d4;
    padding-left: 8em;
    padding-right: 8em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    word-spacing: 2px;
}

section.light{
    color: #2e2e2e;
    padding-left: 8em;
    padding-right: 8em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    word-spacing: 2px;
}

section h2{
    text-align: center;
}

section input.validMoves{
    margin-top: 1.5%;
}

section input.WvalidMoves{
    margin-top: 3.5%;
}

::selection {
  color: #ffffff; 
  background: #8a8a8a;
}

@media all and (max-width: 1000px) {
    section.dark{
        color: #d4d4d4;
        text-align: left;
        padding-left: 2em;
        padding-right: 2em;
    }

    section.light{
        color: #2e2e2e;
        text-align: left;
        padding-left: 2em;
        padding-right: 2em;
    }
}
